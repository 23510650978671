import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'src/config';
import ENDPOINTS from 'src/constants/endpoints';
import Messages from 'src/theme/Messages';
import { get, post, update, remove } from 'src/utils/rest';
import ACTIONS from 'src/redux/actions/user';

function* checkEmail(action) {
    console.log('check email');
    yield put({ type: ACTIONS.CHECK_EMAIL_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.CHECK_EMAIL, action.payload, false);
        if (res.status) {
        yield put({
            type: ACTIONS.CHECK_EMAIL_SUCCESS,
        });
        } else {
        yield put({ type: ACTIONS.CHECK_EMAIL_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
        type: ACTIONS.CHECK_EMAIL_FAILURE,
        error: Messages.NetWorkError,
        });
    }
}

function* login(action) {
    yield put({ type: ACTIONS.LOGIN_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.LOGIN, action.payload, false);
        if (res.status) {
        yield put({
            type: ACTIONS.LOGIN_SUCCESS,
            payload: res.data
        });
        } else {
        yield put({ type: ACTIONS.LOGIN_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.LOGIN_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* register(action) {
    yield put({ type: ACTIONS.REGISTER_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.REGISTER, action.payload, false, false);
        if (res.status) {
            yield put({
                type: ACTIONS.REGISTER_SUCCESS,
                payload: res.data
            });
        } else {
            yield put({ type: ACTIONS.REGISTER_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.REGISTER_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* updateProfile(action) {
    yield put({ type: ACTIONS.UPDATE_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.UPDATE, action.payload, true, false);
        if (res.status) {
            yield put({
                type: ACTIONS.UPDATE_SUCCESS,
                payload: res.data
            });
        } else {
            yield put({ type: ACTIONS.UPDATE_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.UPDATE_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* loadUserDetail(action) {
    yield put({ type: ACTIONS.LOAD_USER_REQUEST });
    try {
        const res = yield call(get, API_BASE_URL + ENDPOINTS.LOAD_DETAIL + action.id);
        if (res.status) {
            yield put({
                type: ACTIONS.LOAD_USER_SUCCESS,
                payload: res.data,
            });
        } else {
            yield put({ type: ACTIONS.LOAD_USER_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.LOAD_USER_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* forgotPassword(action) {
    yield put({ type: ACTIONS.FORGOT_PASSWORD_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.FORGOT_PASSWORD, action.payload, false);
        if (res.status) {
            yield put({
                type: ACTIONS.FORGOT_PASSWORD_SUCCESS
            });
        } else {
            yield put({ type: ACTIONS.FORGOT_PASSWORD_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.FORGOT_PASSWORD_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* resetPassword(action) {
    yield put({ type: ACTIONS.RESET_PASSWORD_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.RESET_PASSWORD, action.payload, false);
        if (res.status) {
            yield put({
                type: ACTIONS.RESET_PASSWORD_SUCCESS
            });
        } else {
            yield put({ type: ACTIONS.RESET_PASSWORD_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.RESET_PASSWORD_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* requestVerifyEmail(action) {
    yield put({ type: ACTIONS.REQUEST_VERIFY_PHONE_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.REQUEST_VERIFY_EMAIL, action.payload, false);
        if (res.status) {
            yield put({
                type: ACTIONS.REQUEST_VERIFY_EMAIL_SUCCESS
            });
        } else {
            yield put({ type: ACTIONS.REQUEST_VERIFY_EMAIL_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.REQUEST_VERIFY_EMAIL_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* verifyEmail(action) {
    yield put({ type: ACTIONS.VERIFY_EMAIL_REQUEST });
    try {
        const res = yield call(post, API_BASE_URL + ENDPOINTS.VERIFY_EMAIL, action.payload, false);
        if (res.status) {
            yield put({
                type: ACTIONS.VERIFY_EMAIL_SUCCESS
            });
        } else {
            yield put({ type: ACTIONS.VERIFY_EMAIL_FAILURE, error: res.error });
        }
    } catch (error) {
        yield put({
            type: ACTIONS.VERIFY_EMAIL_FAILURE,
            error: Messages.NetWorkError,
        });
    }
}

function* requestVerifyPhone(action) {
    
}

function* verifyPhone(action) {
    
}


const userSagas = [
    takeLatest(ACTIONS.CHECK_EMAIL, checkEmail),
    takeLatest(ACTIONS.LOGIN, login),
    takeLatest(ACTIONS.REGISTER, register),
    takeLatest(ACTIONS.FORGOT_PASSWORD, forgotPassword),
    takeLatest(ACTIONS.RESET_PASSWORD, resetPassword),
    takeLatest(ACTIONS.REQUEST_VERIFY_EMAIL, requestVerifyEmail),
    takeLatest(ACTIONS.VERIFY_EMAIL, verifyEmail),
    takeLatest(ACTIONS.REQUEST_VERIFY_PHONE, requestVerifyPhone),
    takeLatest(ACTIONS.VERIFY_PHONE, verifyPhone),
    takeLatest(ACTIONS.UPDATE, updateProfile),
    takeLatest(ACTIONS.LOAD_USER, loadUserDetail),
];

export default userSagas;